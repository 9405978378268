export const billStateCheck = (bill = {}) => ({
  p2pEligible:
    bill.promiseToPay
    && bill.promiseToPay.requirements
    && bill.promiseToPay.requirements.length
    && bill.promiseToPay.requirements[0].date,

  autopayEnabled: bill.summary && bill.summary.autoPayEnabled && bill.summary.autoPayValid,
  autopaySuspend: bill.summary && !!(bill.summary.autoPayEnabled && !bill.summary.autoPayValid),

  pastDue: bill.summary && bill.summary.pastDueBalanceRemaining > 0,

  delinquent: !!(bill.delinquency && bill.delinquency.delinquencyStatus === 'DELINQUENT'),
});

export const toggleEcobillValue = deliveryMethod => (deliveryMethod === 'ELECTRONIC' ? 'PAPER' : 'ELECTRONIC');

// | delinquencyStatus    | softDisconnect | nonPayDisconnectFlag | Result                     |
// |----------------------|----------------|----------------------|----------------------------|
// | NOT_DELINQUENT       | -              | -                    | NORMAL                     |
// | DELINQUENT           | false          | false                | PENDING_VOLUNTARY_DISCONNECT|
// | DELINQUENT           | true           | false                | COMPLETED_VOLUNTARY_DISCONNECT|
// | DELINQUENT           | false          | true                 | PENDING_NON_PAY_DISCONNECT |
// | DELINQUENT           | true           | true                 | COMPLETED_NON_PAY_DISCONNECT|
export const getAccountCollectionState = ({
  delinquencyStatus,
  softDisconnect,
  nonPayDisconnectFlag,
}) => {
  if (delinquencyStatus === 'NOT_DELINQUENT') return 'NORMAL';

  if (delinquencyStatus === 'DELINQUENT') {
    const status = softDisconnect ? 'COMPLETED' : 'PENDING';
    const type = nonPayDisconnectFlag ? 'NON_PAY' : 'VOLUNTARY';
    return `${status}_${type}_DISCONNECT`;
  }

  return '';
};
