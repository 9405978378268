import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { flagEnabled } from '../../helpers/featureFlags';
import { joinClass } from '../../helpers/component';
import PaymentSummary from '../../components/PaymentSummary';
import PaymentMessage from '../../components/PaymentMessage';
import PageTitle from '../../components/PageTitle';

import { sendChannelTracking } from '../../actions/channelTracking';
import {
  SCHEDULED_PAYMENT_CONFIRM,
} from '../../helpers/routes';
import { kibanaLog } from '../../helpers/logger';
import Jump from '../Jump';

const ScheduledPaymentReview = ({
  handleUpdateScheduledPayment,
  history,
  handleSetSuccess,
  review,
  isHarness,
  submitting,
  bill,
  payment,
  handleSendChannelTracking,
  deviceFingerprintId,
}) => {
  const txnDataPaymentEnabled = flagEnabled('txnDataPaymentEnabled');
  const onFormSubmit = (e) => {
    e.preventDefault();

    kibanaLog('payment_form_submit', {
      form: 'Scheduled/Review',
    });

    return handleUpdateScheduledPayment(review).then(() => {
      handleSetSuccess();
      if (payment.promisedToPay) {
        handleSendChannelTracking({ id: 'P2P_MODIFY_COMPLETED', interactionType: 'BILLING_PAYMENTS' });
      }
      return history.push(SCHEDULED_PAYMENT_CONFIRM);
    }).catch(() => {
      if (payment.promisedToPay) {
        handleSendChannelTracking({ id: 'P2P_MODIFY_FAILED', interactionType: 'BILLING_PAYMENTS' });
      }
      history.goBack();
    });
  };

  return (
    <>
      {txnDataPaymentEnabled && !deviceFingerprintId && (
        // This loads CPC's "standalone" component - there is no UI
        <Jump cpcPageType="DeviceFingerPrintId" />
      )}
      <form
        onSubmit={onFormSubmit}
        noValidate
      >
        <PageTitle>Review your changes</PageTitle>

        <div className="payment-section">
          <PaymentSummary summary={review} />
          <PaymentMessage bill={bill} payment={review} type="review" />
        </div>

        <div className="action action--right pt6">
          <div className="action__item">
            <button
              className={joinClass('button button--primary', submitting && 'is-loading')}
              type="submit"
              disabled={submitting}
            >
              Submit Changes
            </button>
          </div>
          {!isHarness && (
            <div className="action__item">
              <button className="button" type="button" onClick={history.goBack}>
                Back
              </button>
            </div>
          )}
        </div>
      </form>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const pmtId = ownProps.match.params.id;
  const {
    scheduledPayments: { payments },
  } = state;

  const payment = payments.find(item => item.id === pmtId) || {};

  return {
    payment,
    // `deviceFingerprintId` will be in sessionStorage if the user went through CPC's tokenization
    // of a new payment method.
    // If it's not there then we will load CPC's standalone template to get it.
    deviceFingerprintId: sessionStorage.getItem('maw_deviceFingerprintId'),
  };
};

const mapDispatchToProps = dispatch => ({
  handleSendChannelTracking: bindActionCreators(sendChannelTracking, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScheduledPaymentReview);
