const DEFAULTS = {
  global: {
    xapInterstitial: true,
    xapSoftDisco: false,
    flexPromo: false,
    peacockPromo: false,
    xapExitEnabled: true,
    channelTracking: true,
    collections2: true,
    kibana: {
      enabled: false,
    },
  },
  'bill-pay': {
    // Payment setup page
    paymentSetup: {
      enabled: true,
    },
    // crsId/consentId flow
    consent: {
      // Whether to enable consent flow at all
      enabled: true,
      // Whether ecobill is presented as an option for consent flow
      // TODO: remove; this is stable now
      ecobill: true,
      // Whether to fetch accepted contracts when in consent flow
      acceptedContracts: false,
    },
    p2pMessaging: true,
    xapExitPlanEnabled: true, // if false, redirect to payflow/suppress CTA
    cmsPromoModule: true,
    applePayEnabled: true, // enable applePay
    kibana: {
      enabled: false,
    },
    enableLiteACH: false,
    enableGlobalNav: true,
    forceGlobalNav: true,
    auth15Disabled: true,
    stepUpEnabled: false,
    txnDataTokenizationEnabled: true,
    txnDataPaymentEnabled: true,
    plaidEnabled: true,
    sampleFeature: {
      // If true, and `enabled` is false, will display a shingle
      disabledShingle: 'true',
      // Types are `modal` and `pushdown`.  If this value is not present, defaults to `pushdown`
      disabledType: 'pushdown',
      // List of available icons varies by app
      disabledIcon: 'icon-circle-exclamation-red',
      // Alloes inline HTML; will be sanitized
      disabledHeadline: 'Sample Feature is Offline',
      // Allows block-level HTML; will be sanitized.
      disabledMessage: `
        Sample feature is disabled; alternately, you can use another feature, or scream into the inky void.
        <a href="https://en.wikipedia.org/wiki/Existentialism">Read more...</a>
      `,
      // Whether the user can dismiss the shingle.  Defaults to true.
      // WARNING: setting a modal to non-dismissable effectively disables the site
      //  when the feature's off
      disabledDismissable: 'true',
      // What tracking-module ID to use when disabled feature shingle appears
      disabledTracking: 'sample-feature-disabled',
      // A link the user can browse to for information or alternate functionality
      disabledAction: '#',
      // Text for the action.  Both fields must be populated for link to appear
      disabledActionText: 'Reticulate Splines',
      // Classname for the action; defaults to "button button--primary"
      disabledActionClass: 'button button--secondary',
      // Where the shingle should be displayed; default is all pages.
      disabledPaths: '/new, /methods/edit/*',
      // Whether the feature is enabled or not.
      enabled: 'false',
    },
  },
};

export default DEFAULTS;
