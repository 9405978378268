import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PageSection, LoadingCard } from 'adc-ui-components';

import { sendChannelTracking } from '../../actions/channelTracking';
import { XFINITY_ASSISTANCE_PLAN_FAILED } from '../../helpers/channelTracking';
import { flagEnabled } from '../../helpers/featureFlags';
import { joinClass } from '../../helpers/component';
import { kibanaLog } from '../../helpers/logger';
import Card from '../../components/Card';
import AutopayCheckbox from '../../components/AutopayCheckbox';
import { setSubmit, setSuccess, setError } from '../../actions/userMessages';
import { scrollToInvalid, getErrorFromResponse } from '../../helpers/errors';
import withCmsContent from '../../helpers/withCmsContent';
import PaymentSummary from '../../components/PaymentSummary';
import paymentMock from '../../helpers/paymentMock';
import PageTitle from '../../components/PageTitle';
import PaymentPlanSummary from '../../components/PaymentPlanSummary';

import {
  PAYMENT_PLAN_CONFIRMATION,
  PAYMENT_PLAN,
} from '../../helpers/routes';
import { submitPayment } from '../../actions/payment';
import Jump from '../Jump';

class PaymentPlanReview extends Component {
  constructor() {
    super();

    this.state = {
      autopay: false,
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onError = this.onError.bind(this);
  }

  componentDidMount() {
    const {
      review,
      history,
      showAutoPay,
      bill,
    } = this.props;

    // if no review data go back to payment
    if (!review) {
      const mock = paymentMock(false);
      if (mock) {
        const { form, bill: mockBill } = mock;
        Object.assign(bill, mockBill);
        this.setState({ showAutoPay: true, review: form });
        return;
      }
      history.push(PAYMENT_PLAN);
    }

    // put this in the state because the enable autopay
    // call will complete before payment submit, and makes
    // updates the global store
    this.setState({ showAutoPay });
  }

  onFormSubmit(e) {
    const {
      handleSubmitPayment,
      history,
      handleSetSubmit,
    } = this.props;
    const { autopay } = this.state;

    e.preventDefault();

    handleSetSubmit();

    kibanaLog('payment_form_submit', {
      form: 'PaymentPlan/Review',
    });

    handleSubmitPayment({ autopay }).then(() => {
      history.push(PAYMENT_PLAN_CONFIRMATION);
    }).catch(this.onError);
  }

  onError(err = {}) {
    const {
      history,
      handleSetError,
      handleSendChannelTracking,
    } = this.props;
    const error = getErrorFromResponse(err);

    scrollToInvalid();
    handleSendChannelTracking({ id: XFINITY_ASSISTANCE_PLAN_FAILED, interactionType: 'BILLING_PAYMENTS' });

    if (error.message) {
      // persist the unmapped error in the state
      // then clear them on resubmit
      handleSetError({ text: error.message });
      history.push(PAYMENT_PLAN);
      throw error;
    }

    handleSetError({ showModal: true });
    history.push(PAYMENT_PLAN);
  }

  render() {
    const {
      autopay,
      loading,
      history,
      review: propsReview,
      submitting,
      content,
      deviceFingerprintId,
    } = this.props;
    const {
      review: stateReview,
    } = this.state;

    const review = propsReview || stateReview;

    const { autopay: autopayVal, showAutoPay } = this.state;

    const txnDataPaymentEnabled = flagEnabled('txnDataPaymentEnabled');

    if (!review) return null;

    return (
      <PageSection>
        {txnDataPaymentEnabled && !deviceFingerprintId && (
          // This loads CPC's "standalone" component - there is no UI
          <Jump cpcPageType="DeviceFingerPrintId" />
        )}
        <form
          onSubmit={this.onFormSubmit}
          noValidate
        >
          <PageTitle>{content('heading.title')}</PageTitle>

          <div className="payment-section">
            <PaymentSummary summary={review} />
          </div>

          { /* don't show section if they are making a full payment */}
          {review.installmentPlan && (
            <div className="payment-section">
              <PaymentPlanSummary
                summary={review}
                loading={loading}
                isReview
              />
              {content('alertCard') && (
                <Card className={`card--${content('alertCard.severity')}`}>
                  <p>
                    {content('alertCard.body')}
                  </p>
                </Card>
              )}
            </div>
          )}

          {loading && <LoadingCard />}

          {!loading && showAutoPay && (
            <AutopayCheckbox
              autopayVal={autopayVal}
              handleSetAutopayState={() => this.setState({ autopay: !autopayVal })}
              autopay={autopay}
              review={review}
            />
          )}

          <div className="action action--right pt6">
            <div className="action__item">
              <button
                className={joinClass('button button--primary', submitting && 'is-loading')}
                disabled={submitting}
                type="submit"
              >
                {content('form.submit')}
              </button>
            </div>
            <div className="action__item">
              <button className="button" type="button" onClick={() => history.goBack()}>
                {content('form.back')}
              </button>
            </div>
          </div>
        </form>
      </PageSection>
    );
  }
}

const mapStateToProps = ({
  autopay: {
    autopay,
    loading: autopayLoading,
  },
  bill: {
    bill,
    loading: billLoading,
    bill: {
      summary: {
        autoPayEnabled,
      } = {},
    } = {},
  },
  payment: {
    submitting: paymentSubmitting,
  },
  paymentPlan: {
    review,
    submitting: paymentPlanSubmitting,
  },
}) => ({
  autopay,
  bill,
  review,
  loading: autopayLoading || billLoading,
  showAutoPay: !autoPayEnabled && !review?.walletId,
  submitting: paymentPlanSubmitting || paymentSubmitting,
  // `deviceFingerprintId` will be in sessionStorage if the user went through CPC's tokenization
  // of a new payment method.
  // If it's not there then we will load CPC's standalone template to get it.
  deviceFingerprintId: sessionStorage.getItem('maw_deviceFingerprintId'),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  handleSubmitPayment: submitPayment,
  handleSetSubmit: setSubmit,
  handleSetSuccess: setSuccess,
  handleSetError: setError,
  handleSendChannelTracking: sendChannelTracking,
}, dispatch);

export default withCmsContent('content', {
  'ltip-payment-plan': 'Review',
})(connect(mapStateToProps, mapDispatchToProps)(PaymentPlanReview));
